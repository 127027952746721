.order-modal {
  background-color: #ffffff;
  height: calc(100vh - 50px);
  width: 650px;
  margin: 24px auto;
  border-radius: 6px;

  .header {
    padding: 14px 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #ffffff;
    justify-content: space-between;
    border-bottom: 1px solid #eeeeee;
    border-top-left-radius: 6px;
    border-top-tight-radius: 6px;
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow-y: scroll;
    width: 100%;
    height: calc(100vh - 103px);
    justify-content: flex-start;
    position: relative;
    gap: 12px;

    .status-content {
      display: flex;
      width: 100%;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;

      .status {
        display: flex;
        flex-direction: row;
        width: 100%;
        padding: 12px 16px;
        border-radius: 6px;
      }
    }

    .online-doc {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: flex-start;
      gap: 8px;

      .title {
        width: 100%;
        font-size: 15px;
        color: #A3ACB6;
        line-height: 1.3;
        font-weight: 600;
        border-bottom: 1px solid rgba(190, 190, 182, 0.3);
        padding-bottom: 10px;
      }

      .row {
        width: 100%;
      }
    }

    .order-photos {
      display: flex;
      flex-direction: column;
      alignItems: center;
      width: 100%;
      gap: 8px;
      margin-top: 20px;
      border-top: 1px solid rgba(190, 190, 182, 0.3);
    }

    .driver-info {
      display: flex;
      flex-direction: column;
      alignItems: center;
      width: 100%;
      gap: 8px;

      span {
        font-size: 15px;
        line-height: 1.3;
        font-weight: 600;
        width: 100%;
        border-bottom: 1px solid rgba(190, 190, 182, 0.3);
        padding: 5px 5px 5px 0px;
      }
    }

    .order-info {
      display: flex;
      flex-direction: column;
      alignItems: center;
      width: 100%;
      gap: 8px;

      span {
        font-size: 15px;
        line-height: 1.3;
        font-weight: 600;
        width: 100%;
        border-bottom: 1px solid rgba(190, 190, 182, 0.3);
        padding: 5px 5px 5px 0px;
      }
    }

    .sender {
      display: flex;
      flex-direction: column;
      alignItems: center;
      width: 100%;
      gap: 8px;

      span {
        font-size: 15px;
        line-height: 1.3;
        font-weight: 600;
        width: 100%;
        border-bottom: 1px solid rgba(190, 190, 182, 0.3);
        padding: 5px 5px 5px 0px;
      }
    }

    .recipient {
      display: flex;
      flex-direction: column;
      alignItems: center;
      width: 100%;
      gap: 8px;

      span {
        font-size: 15px;
        line-height: 1.3;
        font-weight: 600;
        width: 100%;
        border-bottom: 1px solid rgba(190, 190, 182, 0.3);
        padding: 5px 5px 5px 0px;
      }
    }

    .order-grade{
      display: flex;
      align-items: center;
      width: 100%;
      gap: 6px;
    }
    .order-feedback{
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 4px;
    }
  }
}
