
.freedom-pay__title {
  text-align: left;
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 20px;
}

.freedom-pay__filter {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  padding: 0 0 20px 0;
}

.freedom-pay__content {
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: center;
}

.freedom-pay__table {
  font-size: 14px;
  text-align: left;
  background-color: #ffffff;

  td {
    padding: 5px 12px;
    overflow-wrap: break-word;
    max-width: 350px;
  }
}

