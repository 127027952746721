.c-modal {
  background-color: #ffffff;
  max-height: calc(100vh - 20px);
  width: 700px;
  margin: 24px auto;
  border-radius: 6px;

  &__header {
    padding: 14px 24px;
    display: flex;
    align-items: center;
    background-color: #ffffff;
    justify-content: space-between;
    border-bottom: 1px solid #eeeeee;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;

    & h3 {
      margin: 0px;
      padding: 0px;
      font-size: 16px;
      background: #ffffff;
      font-weight: 600;
    }

    & span {
      cursor: pointer;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    max-height: calc(100vh - 103px) !important;
    text-align: left;
    padding: 24px 24px;
    gap: 10px;

    .content-row {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: flex-start;
      gap: 10px;
    }

    .content-column {
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      gap: 4px;
    }

    .photo {
      width: 250px;
      height: 280px;
      object-fit: cover;
      border-radius: 5px;
    }

    .editor {
      background: #ffffff;
      width: 100%;
    }

    .btn-bottom {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 30px;
    }
  }

  .hidden {
    display: none;
  }


}
