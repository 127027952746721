.photo {
  width: 150px;
  height: 150px;
}

.action {
  display: flex;
  gap: 10px;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  padding: 5px 0;
  cursor: pointer;
}

.news-table {
  font-size: 14px;
  text-align: left;
  background-color: #ffffff;

  td {
    min-width: 170px;

    .description {
      //display: -webkit-box;
      ////max-width: 200px;
      //-webkit-line-clamp: 20;
      //-webkit-box-orient: vertical;
      //overflow: hidden;
      //max-height: 400px !important;
    }
  }
}
