
.feedback__title {
  text-align: left;
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 20px;
}

.feedback__content {
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: center;
}

.feedback__table {
  font-size: 14px;
  text-align: left;
  background-color: #ffffff;

  td {
    padding: 5px 12px;
    overflow-wrap: break-word;
    max-width: 350px;
  }

  .unread {
    background: #fe9b9b;
    cursor: pointer;
  }

}

.feedback__filter {
  margin: 5px;


}

