
.warning {
  background-color: #f36969 !important;
  color: #ffffff;
}

.btn {
  background-color: #a6a630;
  color: #fff;
}

.payment-active {
  background: #0316fc;
  color: #ffffff;
}
