
.notification__body{
    position: fixed;
    top: 10px;
    right: 15px;
    z-index: 3;
}
.notification{
    position: relative;
    &__icon{

    }
    &__count{
        border-radius: 28px;
        color: #fff;
        background-color: #C5115E;
        display: inline-flex;
        height: 18px;
        padding: 4px 5px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex-shrink: 0;
        position: absolute;
        left: 18px;
        top: -3px;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
    }
}

