
.insurance__title {
  text-align: left;
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 20px;
}

.insurance__tabs {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 5px;
  height: 50px;

  & .btn {
    background: rgb(60, 179, 113);
    border: none;
    color: #ffffff;
    width: 140px;
    margin: 0px !important;
    border-radius: 0px !important;
    outline: none;
  }

  & .btn:hover {
    background: #0316fc;
    border-right: 8px;
    color: #ffffff;
    cursor: pointer;
  }

  & .btn-active {
    background: #0316fc !important;
  }

  & .btn-right {
    border-left: 1px solid #ffffff;
  }
}

.insurance__filter {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  padding: 0px 0px 20px 0px;
}

.insurance__content {
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: center;
}

.insurance__table {
  font-size: 14px;
  text-align: left;
  background-color: #ffffff;

  td {
    padding: 5px 12px;
    overflow-wrap: break-word;
    max-width: 350px;

    .action {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }
  }
}

