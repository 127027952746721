
.menu {
    background-color: #343a40;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;

    min-height: 100vh;
    height: 100%;
    position: fixed !important;
    overflow-y: auto;
    padding: 22px 11px 10px;
    width: 270px;
    z-index: 10;
    display: flex;
    flex-direction: column;
    bottom: 0;
    left: 0;
    right: auto;
    top: 0;

    transition: all 0.5s;
}

.active {
    background-color: #0316fc;
}

.mr-70 {
    margin-right: 70px;
}

.nav-link {
    display: flex !important;
    align-items: center;
    /* justify-content: center; */
    border-radius: 12px;

    text-decoration: 'none';
}

.menu > .scrollbox {
    padding-right: 10px;

    box-sizing: border-box;
    flex-grow: 1;
    margin: 0 -10px;
    overflow-x: hidden;
    overflow-y: auto;
}

.menu > .logo {
    text-align: left;
    padding: 0 0 16px 0;
    /* border-bottom: 1px solid #474d53; */

    display: flex;
    align-items: center;
    justify-content: center;
}

.bars {
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.icon {
    padding: 10px 14px;
}

.menu-item {
    width: 100%;
    padding: 10px 0;
    text-align: left;
    color: #ffffff;
}

.nav-footer {
    /* padding: 15px 0 0 0 !important; */
    padding: 14px 14px !important;
    position: relative;
}

.nav-link:hover {
    background-color: #2a3036;
}

.btn-logout {
    color: #fff;
    text-decoration: none;
    display: flex;
    align-items: center;
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    overflow-y: scroll;
    overflow-x: scroll;
    background: rgb(255, 255, 255);
    box-shadow: inset 0 0 4px #efefef;
    border-radius: 5px;
}

::-webkit-scrollbar-thumb {
    background: rgb(210, 209, 209);
    background: #47515c;
    border-radius: 5px;
    cursor: pointer;
}


