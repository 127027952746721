
.notificationCard {
  padding: 20px 24px 20px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 12px;
  border: 1.6px solid #DDDEE2;
  background: #fff;
  margin-top: 6px;
  margin-bottom: 6px;

  &_content {
    display: flex;
    align-items: center;
    width: 300px;
    justify-content: flex-start;
    overflow: hidden;
  }

  &_destination {
    display: flex;
    align-items: center;
    flex-grow: 1;
    margin: 0 45px;
    justify-content: space-between;
    width: 100%;
    overflow: hidden;
  }

  &_time {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.content {
  &_img {
    margin-right: 16px;
    display: flex;
    align-items: center;
  }

  &_title {
    color:#141619;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}

.time {
  &_img {
    margin-right: 6px;
  }

  &_text {
    color: #5A5C62;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.destination {
  &_text {
    color:#141619;
    font-size: 16px;
    font-style: normal;
    // font-weight: 600;
    line-height: normal;
    margin-right: 6px;
  }

  &_status {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 8px 10px;
    border-radius: 44px;
    // background:#3A50C6;
    color: #fff;
  }
}

.writer {
  color:#141619;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-right: 6px;
  white-space: nowrap;
}

.comment {
  color:#141619;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


