.tabs {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 5px;
  padding-bottom: 10px;
  border-bottom: 1px solid #a7afbb;
}

.btn-groups {
  margin-top: 20px;
  margin-left: 20px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 5px;
}

.btn {
  background-color: rgb(60, 179, 113);
  font-size: 14px;
  border: none;
  border-radius: 8px;
  color: #ffffff;
}

.btn-add {
  background-color: rgb(60, 179, 113);
}

.btn-filter {
  background-color: #A3195B;
}

.btn-clear-filter {
  background-color: rgb(167 163 165);
}

.btn-cancel {
  background-color: rgb(167 163 165);
}


